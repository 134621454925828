@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
  overflow-x: hidden !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 0.2rem;
  border-radius: 0.6rem;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.6rem;
  background-color: #16a34a;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0f8b3d;
}

.scrolling-text {
  animation: scroll-right-to-left 20s linear infinite;
  white-space: nowrap;
}

.scrolling-text:hover {
  animation-play-state: paused;
}

@keyframes scroll-right-to-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
